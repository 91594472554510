import { DialogService }                                     from 'aurelia-dialog';
import { bindable, inject }                                  from 'aurelia-framework';
import { I18N }                                              from 'aurelia-i18n';
import { PLATFORM }                                          from 'aurelia-pal';
import { activationStrategy }                                from 'aurelia-router';
import { BaseListViewModel }                                 from 'base-list-view-model';
import { ApprovedWorkingFormulaAdditionalInformationsModal } from 'modules/bituminous-mixtures/mixtures/approved-working-formulas/additional-informations-modal';
import { FilterFormSchema }                                  from 'modules/dynamic-lists/mixtures/approved-working-formulas/filter-form-schema';
import { DynamicListsRepository }                            from 'modules/dynamic-lists/services/repository';
import { InfoDialog }                                        from 'resources/elements/html-elements/dialogs/info-dialog';
import { AppContainer }                                      from 'resources/services/app-container';
import { Downloader }                                        from 'resources/services/downloader';

@inject(AppContainer, DynamicListsRepository, I18N, Downloader, FilterFormSchema, DialogService)
export class DynamicListApprovedWorkingFormula extends BaseListViewModel {

    routePrefix      = 'dynamic-listings.mixtures.approved-working-formulas';
    permissionPrefix = this.routePrefix;

    @bindable headerTitle = 'listing.dynamic-lists.approved-working-formulas';
    @bindable newRecordRoute;
    @bindable newRecordRouteParams;
    @bindable repository;
    @bindable datatable;

    constructor(appContainer, repository, i18N, downloader, filterFormSchema, dialogService) {
        super(appContainer);

        this.repository       = repository;
        this.i18N             = i18N;
        this.downloader       = downloader;
        this.filterFormSchema = filterFormSchema;
        this.dialogService    = dialogService;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return this.appContainer.globalConfig.defaultListView;
    }

    /**
     * Determines activation strategy
     *
     * @return {'replace'}
     */
    determineActivationStrategy() {
        return activationStrategy.replace;
    }

    /**
     * Handles canActivate event
     *
     * @param params
     *
     * @returns {Promise.<*>|Promise}
     */
    async canActivate(params) {
        return this.appContainer.authenticatedUser.can([this.permissionPrefix + '.manage', this.permissionPrefix + '.view']);
    }

    /**
     * Handles activate event
     */
    async activate() {
        super.activate();

        this.defineDatatable();

        this.authUser = this.appContainer.authenticatedUser;

        this.filterModel  = this.filterFormSchema.model(this);
        this.filterSchema = await this.filterFormSchema.schema(this, this.authUser.isBuilder);
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:         {
                search: (criteria) => this.repository.approvedWorkingFormula(criteria),
            },
            actionsContextMenu: false,
            selectable:         true,
            destroySelected:    false,
            options:            [
                {
                    label:   'form.button.export-to-excel',
                    icon:    'icon-file-excel',
                    action:  () => this.exportListing(this.i18N.tr('form.field.approved-working-formulas'), !this.datatable.instance.selectedRows.length ? null : this.datatable.instance.selectedRows),
                    visible: () => this.authUser.can([this.permissionPrefix + '.manage', this.permissionPrefix + '.view']),
                },
            ],
            sorting:            [
                {
                    column:    0,
                    direction: 'desc',
                },
                {
                    column:    4,
                    direction: 'desc',
                },
            ],
            columns:            [
                {
                    data:  'number',
                    name:  'bm_approved_working_formulas.number',
                    title: 'form.field.fta-number',
                },
                {
                    data:  'concession_name',
                    name:  'concession_translations.description',
                    title: 'form.field.concession',
                },
                {
                    data:       'entity_name',
                    name:       'entity_translations.name',
                    title:      'form.field.entity',
                    searchable: false,
                    orderable:  false,
                    type:       'entityTeam',
                },
                {
                    data:  'highway_name',
                    name:  'highway_translations.designation',
                    title: 'form.field.highway',
                },
                {
                    data:  'intervention_type_name',
                    name:  'intervention_type_translations.designation',
                    title: 'form.field.intervention-type',
                },
                {
                    data:  'lot_intervention_name',
                    name:  'lot_intervention_translations.designation',
                    title: 'form.field.lot',
                },
                {
                    data:  'date',
                    name:  'bm_approved_working_formulas.date',
                    title: 'form.field.date',
                },
                {
                    data:  'approval_date',
                    name:  'bm_approved_working_formulas.approval_date',
                    title: 'form.field.approval-date',
                },
                {
                    data:  'material_type_name',
                    name:  'material_type_translations.name',
                    title: 'form.field.material-type',
                },
                {
                    data:  'layer_name',
                    name:  'bm_layer_translations.name',
                    title: 'form.field.layer-name',
                },
                {
                    data:  'formulation_study_name',
                    name:  'bm_formulation_study_translations.designation',
                    title: 'form.field.associated-study-number',
                },
                {
                    data:       'manufacturing_plant_transpositions',
                    name:       'manufacturing_plant_transpositions',
                    title:      'form.field.material-plant-transposition-associated-number',
                    searchable: false,
                    orderable:  false,
                    type:       'processed',
                    processor:  (row) => this.joinArrayInList(row, 'manufacturing_plant_transpositions'),
                },
                {
                    data:       'bm_sample_name',
                    name:       'bm_sample_name',
                    title:      'form.field.bm-sample-number',
                    searchable: false,
                    orderable:  false,
                },
                {
                    data:  'created_by',
                    name:  'user_entity_team_lots.identifier',
                    title: 'form.field.created-by',
                    type:  'userEntityTeamLot',
                },
                {
                    data:            'created_at',
                    name:            'bm_approved_working_formulas.created_at',
                    title:           'form.field.created-at',
                    valueConverters: [
                        {
                            name: 'dateTimeFormat',
                        },
                    ],
                },
                {
                    data:          'is_validated',
                    name:          'is_validated',
                    title:         'form.field.validated',
                    type:          'custom-cell',
                    fullDataModel: 'validator',
                    viewModel:     PLATFORM.moduleName('modules/core/custom-listing-cells/checkmark/index'),
                    orderable:     false,
                    searchable:    false,
                },
                {
                    data:       'additional_info',
                    name:       'additional_info',
                    title:      'form.title.additional-info',
                    type:       'custom-cell',
                    icon:       'icon-info3',
                    show:       (row) => row.has_additional_information,
                    action:     (row) => this.openModal(row.id, ApprovedWorkingFormulaAdditionalInformationsModal),
                    viewModel:  PLATFORM.moduleName('modules/core/custom-listing-cells/action-icon/index'),
                    orderable:  false,
                    searchable: false,
                },
                {
                    data:  'observations',
                    name:  'bm_approved_working_formulas.observations',
                    title: 'form.field.observations',
                },
            ],
        };
    }

    /**
     * Executes a given action if it is selected at least one row
     *
     * @param action
     * @returns {*}
     */
    ensureSelectedRows(action) {
        if (!this.datatable.instance.selectedRows.length) {
            return this.dialogService.open({
                viewModel: InfoDialog,
                model:     {
                    body:  this.appContainer.i18n.tr('message.select-at-least-one-record'),
                    title: this.appContainer.i18n.tr('text.attention'),
                },
            });
        }

        action();
    }

    /**
     * Call promise then show response notice and reload datatable
     * @param call
     */
    callThenResponse(call) {
        call.then(response => {
            this.appContainer.notifier.responseNotice(response);
            this.datatable.instance.reload();
        });
    }

    /**
     * Opens the given modal
     */
    openModal(model, viewModel) {
        return this.dialogService
            .open({ viewModel: viewModel, model: model })
            .whenClosed((response) => {
                if (!response.wasCancelled) {
                    this.datatable.instance.reload();
                }
            });
    }

    /**
     * Exports the listing to Excel
     * Assumptions: a `filterModel`, `downloader` instance and repository `export` method must exist
     *
     * @param filename
     * @param selectedRows
     */
    exportListing(filename = 'listing', selectedRows = null) {
        const filterModel = Object.assign({
            sorting: this.datatable.instance.getSortingColumn(),
        }, this.filterModel);

        if (selectedRows) {
            filterModel.selectedRows = selectedRows;
        }

        this.repository.exportApprovedWorkingFormula(filterModel).then(response => this.downloader.download(response, filename, 'xlsx'));
    }

    /**
     * Make list of allowed users
     *
     * @param row
     * @param column
     * @returns {string}
     */
    joinArrayInList(row, column) {
        let html = '<ul>';

        for (let item of row[column]) {
            html += '<li>';
            html += item;
            html += '</li>';
        }

        html += '</ul>';
        return html;
    }
}

