import { inject }           from 'aurelia-framework';
import { BaseRepository }   from 'modules/core/services/base-repository';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class DynamicListsRepository extends BaseRepository {
    baseUrl = 'dynamic-lists';

    /**
     * Returns a list of tests based on the criteria
     */
    searchTests(criteria, type) {
        if (type !== 'lab') {
            return this.inSitu(criteria);
        }

        return this.lab(criteria);
    }

    /**
     * Exports by type
     *
     * @param criteria
     * @param type
     * @return {*}
     */
    exportByType(criteria, type) {
        if (type !== 'lab') {
            return this.exportInSitu(criteria);
        }

        return this.exportLab(criteria);
    }

    /**
     * Get list of lab tests
     */
    lab(criteria) {
        return this.httpClient.post(`${this.baseUrl}/lab`, criteria);
    }

    /**
     * Exports list of lab tests
     */
    exportLab(criteria) {

        return this.httpClient.rawPost(`${this.baseUrl}/lab/export`, criteria)
            .then(response => {
                if (response.status !== 200) {
                    throw { status: false, message: 'Error' };
                } else {
                    return response.blob();
                }
            });
    }

    /**
     * Get list of in-situ tests
     */
    inSitu(criteria) {
        return this.httpClient.post(`${this.baseUrl}/in-situ`, criteria);
    }

    /**
     * Exports list of lab tests
     */
    exportInSitu(criteria) {

        return this.httpClient.rawPost(`${this.baseUrl}/in-situ/export`, criteria)
            .then(response => {
                if (response.status !== 200) {
                    throw { status: false, message: 'Error' };
                } else {
                    return response.blob();
                }
            });
    }

    /**
     * Get list of test bulletins by listing revision
     */
    testBulletins(criteria) {
        return this.httpClient.post(`${this.baseUrl}/test-bulletins`, criteria);
    }

    /**
     * Get list of columns for general information
     */
    testBulletinsFields(criteria) {
        return this.httpClient.post(`${this.baseUrl}/test-bulletins-fields`, criteria);
    }

    /**
     * Get list of test bulletins by listing revision
     */
    generalColumns(criteria) {
        return this.httpClient.post(`${this.baseUrl}/general-columns`, criteria);
    }

    generateHeaders(criteria) {
        return this.httpClient.post(`${this.baseUrl}/generate-headers`, criteria);
    }

    /**
     * Get list for Manufacturing Plant Transposition
     */
    manufacturingPlantTransposition(criteria) {
        return this.httpClient.post(`${this.baseUrl}/manufacturing-plant-transposition`, criteria);
    }

    /**
     * Exports a search for Manufacturing Plant Transposition given some criteria
     *
     * @param criteria
     *
     * @returns {*}
     */
    exportManufacturingPT(criteria) {
        return this.httpClient.rawPost(`${this.baseUrl}/export-manufacturing-pt`, criteria)
            .then(response => {
                if (response.status !== 200) {
                    throw { status: false, message: 'Error' };
                } else {
                    return response.blob();
                }
            });
    }

    /**
     * Get list for Formulation Study
     */
    formulationStudy(criteria) {
        return this.httpClient.post(`${this.baseUrl}/formulation-study`, criteria);
    }

    /**
     * Exports a search for Formulation Study given some criteria
     *
     * @param criteria
     *
     * @returns {*}
     */
    exportFormulationStudy(criteria) {
        return this.httpClient.rawPost(`${this.baseUrl}/export-formulation-study`, criteria)
            .then(response => {
                if (response.status !== 200) {
                    throw { status: false, message: 'Error' };
                } else {
                    return response.blob();
                }
            });
    }

    /**
     * Get list for Approved Working Formula
     */
    approvedWorkingFormula(criteria) {
        return this.httpClient.post(`${this.baseUrl}/approved-working-formula`, criteria);
    }

    /**
     * Exports a search for Approved Working Formula given some criteria
     *
     * @param criteria
     *
     * @returns {*}
     */
    exportApprovedWorkingFormula(criteria) {
        return this.httpClient.rawPost(`${this.baseUrl}/export-approved-working-formula`, criteria)
            .then(response => {
                if (response.status !== 200) {
                    throw { status: false, message: 'Error' };
                } else {
                    return response.blob();
                }
            });
    }
}
